// export const BASE_URL = 'http://localhost/shiv_mfg_api/public/'
export const BASE_URL = 'https://shivgems.kodllin.com/apis/public/'
// export const BASE_URL = 'https://dhanushlgd.kodllin.com/apis/public/'
// export const BASE_URL = 'https://khodiyardiam.kodllin.com/apis/public/'
// export const BASE_URL = 'https://mfg.stavyaexports.com/apis/public/'
// export const BASE_URL = 'https://aksharmfg.kodllin.com/apis/public/'
// export const BASE_URL = 'https://santkrupa.kodllin.com/apis/public/'
// export const BASE_URL = 'https://diarisetest.dkinfoway.com/apis/public/'
// export const BASE_URL = 'https://bluemoonapi.dkinfoway.com/'
// export const BASE_URL = 'http://127.0.0.1:8000/api'

export const BASE_URL_REPORT = 'https://shivgemsreports.kodllin.com/'
// export const BASE_URL_REPORT = 'https://dhanushlgdreports.kodllin.com/'
// export const BASE_URL_REPORT = 'https://khodiyardiamreports.kodllin.com/'
// export const BASE_URL_REPORT = 'https://reports.stavyaexports.com/'
// export const BASE_URL_REPORT = 'https://aksharreports.kodllin.com/'
// export const BASE_URL_REPORT = 'https://santkrupareports.kodllin.com/'
// export const BASE_URL_REPORT = 'http://localhost:5173/'
export const BASE_NAME = 'Shivratna Gems'
// export const BASE_NAME = 'Dhanush Labgrown Diamond'
// export const BASE_NAME = 'Khodiyar Diamond'
// export const BASE_NAME = 'Stavya Exports'
// export const BASE_NAME = 'AKSHAR DIAGEMS'
// export const BASE_NAME = ' SANTKRUPA DIAMOND'
